import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MobileGuard implements CanActivate, CanActivateChild {

  constructor( private router: Router, private storageService: StorageService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateHelper(next);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateHelper(next);
  }

  canActivateHelper(next?, state?) {
    const nextPageUrl = next._routerState.url;
    // for filmbox and film1 we'll have separate mobile responsive pages(old one)
    if((environment.appName === 'filmbox' || environment.appName === 'film1') &&  window.innerWidth <= 768 && Boolean(this.storageService.getLocalStore('u_id')) && nextPageUrl !== '/account/m/home') {
      this.router.navigateByUrl('account/m/home');
      return false;
    } else if(window.innerWidth <= 768 && !Boolean(this.storageService.getLocalStore('u_id'))) {
      this.router.navigateByUrl('auth/signin');
      return false;
    }
    return true;
  }
}
