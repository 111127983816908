export const environment = {
    production: true,
    environmentName:'test',
    apiUrl:'https://api.funroads.tv/api/',
    socialUrl:'https://api.funroads.tv/api/',
    version:'v2/',
    v3_version: 'v3/',
    routeUrl:'https://funroadstest.tv2zdev.com/',
    cookieDomain:'localhost',
    paywallURL: 'https://funroadstestssr.tv2zdev.com/',
    appName: 'funroads',
    htmlAppName: 'film1',
    ezProxyUrl: '',
    isEzProxy: false,
    loader: 'funroads',
    appVersion: '1.0.0',
  };